/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UsersIndex from "views/Users/UsersIndex.js";
import UsersCreate from "views/Users/UsersCreate.js";
import PlansIndex from "views/Plans/PlansIndex.js";
import PlansCreate from "views/Plans/PlansCreate.js";
import CustomersIndex from "views/Customers/CustomersIndex.js";
import CustomersCreate from "views/Customers/CustomersCreate.js";
import DependentsIndex from "views/Dependents/DependentsIndex";
import DependentsCreate from "views/Dependents/DependentsCreate";
import ProposalsIndex from "views/Proposals/ProposalsIndex.js";
import ProposalsCreate from "views/Proposals/ProposalsCreate.js";
import FinantialResponsibleIndex from "views/FinantialResponsible/FinantialResponsibleIndex.js";
import FinantialResponsibleCreate from "views/FinantialResponsible/FinantialResponsibleCreate.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Usuários",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UsersIndex,
    layout: "/admin"
  },
  {
    path: "/create-user",
    name: "Usuários",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UsersCreate,
    layout: "/admin"
  },
  {
    path: "/plans",
    name: "Planos",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: PlansIndex,
    layout: "/admin"
  },
  {
    path: "/create-plan",
    name: "Planos",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: PlansCreate,
    layout: "/admin"
  },
  {
    path: "/customer",
    name: "Clientes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: CustomersIndex,
    layout: "/admin"
  },
  {
    path: "/create-customer",
    name: "Clientes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: CustomersCreate,
    layout: "/admin"
  },
  {
    path: "/dependents",
    name: "Dependentes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: DependentsIndex,
    layout: "/admin"
  },
  {
    path: "/create-dependent",
    name: "Dependentes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: DependentsCreate,
    layout: "/admin"
  },
  {
    path: "/proposals",
    name: "Propostas",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: ProposalsIndex,
    layout: "/admin"
  },
  {
    path: "/create-proposal",
    name: "Propostas",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: ProposalsCreate,
    layout: "/admin"
  },
  {
    path: "/finantial-responsible",
    name: "Responsável Financeiro",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: FinantialResponsibleIndex,
    layout: "/admin"
  },
  {
    path: "/create-finantial-responsible",
    name: "Responsável Financeiro",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: FinantialResponsibleCreate,
    layout: "/admin"
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
