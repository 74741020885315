import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./../App.css";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import * as constants from "../constantsuniodonto";

const userService = require("../services/UserService");
const authService = require("../services/AuthService");

const switchRoutes = (
  <Switch>
    {userService.getStorageUser() != null && (
      <Redirect from="/login" to={`${constants.SITE_URL}/admin/dashboard`} />
    )}
  </Switch>
);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Uniodonto Maceió
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLogged, setIsLogged] = React.useState(false);
  const [errorMessage, setErroMessage] = React.useState(false);

  const classes = useStyles();

  const doLogin = (event) => {
    const data = {
      email,
      password,
    };

    authService.login(data).then(
      (response) => {
        userService.addStorageUser(response);
        setIsLogged(true);
      },
      (error) => {
        setErroMessage(true);
      }
    );
  };

  React.useEffect(() => {
    setErroMessage(false);
    if (userService.getStorageUser() != null) {
      setIsLogged(true);
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      {isLogged && (
        <Switch>
          {userService.getStorageUser() != null && (
            <Redirect
              from="/login"
              to={`${constants.SITE_URL}/admin/dashboard`}
            />
          )}
        </Switch>
      )}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Entrar
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          {errorMessage && (
            <span className="error-message">
              Error ao tentar entrar, verifique se o usuário e/ou senhas estão
              corretos e tente novamente.
            </span>
          )}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={doLogin}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Esqueceu a senhas?
              </Link>
            </Grid>
            {/*<Grid item>*/}
            {/*  <Link href="#" variant="body2">*/}
            {/*    {"Don't have an account? Sign Up"}*/}
            {/*  </Link>*/}
            {/*</Grid>*/}
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
