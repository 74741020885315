import axios from 'axios';
import {getStorageUserToken} from "./UserService";
import * as constants from '../constantsuniodonto';

const API_URL = constants.API_URL;

export const create = async (data) => {
  return axios.post(`${API_URL}/dependents`, data,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const fetch = async () => {
  return axios.get(`${API_URL}/dependents`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const get = async (id) => {
  return axios.get(`${API_URL}/dependents/dependent/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const search = async (query) => {
  return axios.get(`${API_URL}/dependents/search?q=${query}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const update = async (data) => {
  return axios.put(`${API_URL}/dependents`, data,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const remove = async (id) => {
  return axios.delete(`${API_URL}/dependents/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};