import axios from 'axios';
import {getStorageUserToken} from "./UserService";
import * as constants from '../constantsuniodonto';

const userService = require('./UserService');

const API_URL = constants.API_URL;

export const login = async (data) => {
  return axios.post(`${API_URL}/auth/signin`, data);
};

export const verifySession = async (id) => {
  return axios.get(`${API_URL}/users/user/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const logout = async () => {
  userService.removeStorageUser();
};

export const resetPassword = async (id) => {
  return axios.get(`${API_URL}/auth/reset_password`);
};

export const forgotPassword = async () => {
  return axios.get(`${API_URL}/auth/forgot_password`);
};

export const getStorageUser = async () => {
  const localUser = userService.getStorageUser();
  return localUser ? JSON.parse(localUser) : null;
};