import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, HashRouter } from "react-router-dom";
import dotenv from 'dotenv';
import axios from 'axios';

// core components
import Admin from "layouts/Admin.js";
import Login from "layouts/Login.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";

import * as constants from './constantsuniodonto';

dotenv.config();

const userService = require('services/UserService');

const login_url = `${constants.SITE_URL}/#/login`;

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    userService.removeStorageUser();
    setTimeout(() => {
    window.location = login_url;
    }, 2000);
  } else {
    return Promise.reject(error);
  }
});

const hist = createBrowserHistory();

ReactDOM.render(
  <HashRouter history={hist} basename={`${constants.SITE_URL}`} hashType={"slash"}>
    <Switch>
      <Route path={`${constants.SITE_URL}/login`} component={Login} />
      <Route path={`${constants.SITE_URL}/admin`} component={Admin} />
      {(userService.getStorageUser() == null) && (
        <Redirect from="/" to={`${constants.SITE_URL}/login`}/>
      )}
      {(userService.getStorageUser() != null) && (
        <Redirect from="/" to={`${constants.SITE_URL}/admin`}/>
      )}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
