import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomCurrencyInput from "components/CustomCurrencyInput/CustomCurrencyInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useLocation, useHistory } from 'react-router-dom';
import * as constants from "../../constantsuniodonto";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const planService = require('../../services/PlanService');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function PlansPage() {
  
  const {search} = useLocation();
  
  const [isEdit, setIsEdit] = React.useState(false);
  const [typeList, setTypeList] = React.useState(constants.planTypeList);
  const [planTargetList, setPlanTargetList] = React.useState(constants.planTargetList);
  const [personList, setPersonList] = React.useState(constants.planPersonList);
  const [companyList, setCompanyList] = React.useState(constants.companyList);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [entityID, setEntityID] = React.useState(null);
  const [requestMessage, setRequestMessage] = React.useState('');
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = React.useState(false);
  const [plan, setPlan] = React.useState({
    name: '',
    code: '',
    description: '',
    obs: '',
    ans: '',
    amount_1_life: '',
    amount_2_life: '',
    amount_3_life: '',
    amount_4_life: '',
    type: '',
    person: '',
    target: '',
    detail_url: '',
    thumb: '',
    company: '',
    startDate: '',
    endDate: ''
  });
  
  const classes = useStyles();
  
  React.useEffect(() => {
    let id;
    if(search.indexOf('id=') !== -1){
      id = search.replace('?id=', '');
      setEntityID(id);
      planService.get(id).then((response) => {
        setPlan(response.data);
        setIsEdit(true);
      });
    }
  },[]);
  
  let history = useHistory();

  function handleSaveButton(){
    if(isEdit){
      planService.update(plan).then((response) => {
        setRequestMessage('Plano Atualizado com Sucesso');
        setOpenSuccess(true);
      });
    }else{
      planService.create(plan).then((response) => {
        setPlan(response.data);
        history.push(`${constants.SITE_URL}/admin/create-plan?id=${response.data._id}`);
        setEntityID(response.data._id);
        setIsEdit(true);
        setOpenSuccess(true);
        setRequestMessage('Plano Criado com Sucesso');
      });
    }
  }
  
  function handleDeleteButton(){
    planService.remove(entityID).then((response) => {
      history.push(`${constants.SITE_URL}/admin/plans`);
      setOpenSuccess(true);
      setRequestMessage('Plano Removido com Sucesso');
      handleCloseRemoveConfirmation();
    }, (e) => {
      setOpenError(true);
      setRequestMessage('Problema ao remover plano.');
      handleCloseRemoveConfirmation();
    });
  }
  
  const closeAlert = (event, reason) => {
    setOpenSuccess(false);
    setOpenError(false);
  };
  
  const handleClickOpenRemoveConfirmation = () => {
    setOpenRemoveConfirmation(true);
  };
  
  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };
  
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Criar Plano</h4>
              <p className={classes.cardCategoryWhite}>Plano será comercializado pela plataforma</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.name,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          name: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Código do Plano"
                    id="code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.code,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          code: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="ANS"
                    id="ans"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.ans,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          ans: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Tipo do plano"
                    id="planTargetList"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.target,
                      options: planTargetList,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          target: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Finalidade"
                    id="type"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.type,
                      options: typeList,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          type: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                {plan.type === 'Pessoal' && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Tipo do cliente"
                      id="person"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: plan.person,
                        options: personList,
                        onChange: (event) => {
                          setPlan({
                            ...plan,
                            person: event.target.value
                          });
                        }
                      }}
                    />
                  </GridItem>
                )}
                {plan.type === 'Empresarial' && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Empresa"
                      id="company"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: plan.company,
                        options: companyList,
                        onChange: (event) => {
                          setPlan({
                            ...plan,
                            company: event.target.value
                          });
                        }
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="URL da logomarca do plano"
                    id="thumb"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.thumb,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          thumb: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="URL da página de detalhes"
                    id="thumb"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.detail_url,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          detail_url: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Descrição"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      value: plan.description,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          description: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Observação"
                    id="obs"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      value: plan.obs,
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          obs: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_1_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Titular",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: plan.amount_1_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      onChange: (event, value)=> setPlan({
                        ...plan,
                        amount_1_life: value
                      })
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_2_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Um dependente",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: plan.amount_2_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      onChange: (event, value)=> setPlan({
                        ...plan,
                        amount_2_life: value
                      })
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_3_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Dois dependentes",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: plan.amount_3_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      onChange: (event, value)=> setPlan({
                        ...plan,
                        amount_3_life: value
                      })
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_4_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Três ou mais dependentes",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: plan.amount_4_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      onChange: (event, value)=> setPlan({
                        ...plan,
                        amount_4_life: value
                      })
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Início da Divugação"
                    id="startDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.startDate,
                      type: 'date',
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          startDate: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Fim da Divugação"
                    id="endDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: plan.endDate,
                      type: 'date',
                      onChange: (event) => {
                        setPlan({
                          ...plan,
                          endDate: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleSaveButton}>Salvar Plano</Button>
              {isEdit && (
                <Button color="danger" onClick={handleClickOpenRemoveConfirmation}>Excluir PLano</Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="success">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="error">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openRemoveConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRemoveConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Alerta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer remover esse registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteButton} color="danger">
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
