import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useLocation, useHistory } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import * as constants from '../../constantsuniodonto';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import FormControl from "@material-ui/core/FormControl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const finantialResponsibleService = require('../../services/FinantialResponsibleService');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function FinantialResponsibleCreate() {
  
  const {search} = useLocation();
  
  const [isEdit, setIsEdit] = React.useState(false);
  const [typeList, setTypeList] = React.useState(constants.userTypeList);
  const [sexList, setSexList] = React.useState(constants.sexList);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [requestMessage, setRequestMessage] = React.useState('');
  const [entityID, setEntityID] = React.useState(null);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = React.useState(false);
  const [plansList, setPlansList] = React.useState([]);
  
  const [finantialResponsible, setFinantialResponsible] = React.useState({
    name: '',
    born: '',
    phone: '',
    social_number: '',
    email: '',
    sex: '',
    postalCode: '',
    address: '',
    number: '',
    complement: '',
    neightborhood: '',
    city: ''
  });
  
  React.useEffect(() => {
    let id;
    if(search.indexOf('id=') !== -1){
      id = search.replace('?id=', '');
      setEntityID(id);
      finantialResponsibleService.get(id).then((response) => {
        setFinantialResponsible(response.data);
        setIsEdit(true);
      });
    }
  },[]);
  
  let history = useHistory();
  
  function handleSaveButton(){
    if(isEdit){
      finantialResponsibleService.update(finantialResponsible).then((response) => {
        setRequestMessage('Cliente Atualizado com Sucesso');
        setOpenSuccess(true);
      });
    }else{
      finantialResponsibleService.create(finantialResponsible).then((response) => {
        setRequestMessage('Cliente Criado com Sucesso');
        setFinantialResponsible(response.data.finantialResponsible);
        history.push(`${constants.SITE_URL}/admin/create-finantial-responsible?id=${response.data.finantialResponsible._id}`);
        setEntityID(response.data.finantialResponsible._id);
        setIsEdit(true);
        setOpenSuccess(true);
      });
    }
  }
  
  function handleDeleteButton(){
    finantialResponsibleService.remove(entityID).then((response) => {
      history.push(`${constants.SITE_URL}/admin/finantial-responsible`);
      setOpenSuccess(true);
      setRequestMessage('Responsável Financeiro Removido com Sucesso');
    }, () => {
      setRequestMessage(`Problema ao remover Responsável Financeiro`);
      setOpenError(true);
      handleCloseRemoveConfirmation();
    });
  }
  
  const closeAlert = (event, reason) => {
    setOpenSuccess(false);
    setOpenError(false);
  };
  
  const handleClickOpenRemoveConfirmation = () => {
    setOpenRemoveConfirmation(true);
  };
  
  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };
  
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Adicionar Cliente</h4>
              <p className={classes.cardCategoryWhite}>Insira todos os dados do novo cliente</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.name,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          name: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Nascimento"
                    id="born"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.born,
                      type: 'text',
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          born: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Telefone"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.phone,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          phone: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="CPF"
                    id="social_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.social_number,
                      type: 'number',
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          social_number: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.email,
                      type: 'email',
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          email: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Sexo"
                    id="sex"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.sex,
                      options: sexList,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          sex: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="CEP"
                    id="postalCode"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.postalCode,
                      type: 'number',
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          postalCode: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Logradouro"
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.address,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          address: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Número"
                    id="addressNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.number,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          number: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Complemento"
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.complement,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          complement: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Bairro"
                    id="neightborhood"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.neightborhood,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          neightborhood: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Cidade"
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: finantialResponsible.city,
                      onChange: (event) => {
                        setFinantialResponsible({
                          ...finantialResponsible,
                          city: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleSaveButton}>Salvar Responsável</Button>
              {isEdit && (
                <Button color="danger" onClick={handleClickOpenRemoveConfirmation}>Excluir Responsável Financeiro</Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="success">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="error">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openRemoveConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRemoveConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Alerta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer remover esse registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteButton} color="danger">
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
