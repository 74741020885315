import axios from 'axios';
import {getStorageUserId, getStorageUserToken} from "./UserService";
import * as constants from '../constantsuniodonto';

const API_URL = constants.API_URL;

export const create = async (data) => {
  return axios.post(`${API_URL}/proposals`, data,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const fetch = async (arg) => {
  return axios.get(`${API_URL}/proposals?status=${arg.proposalStatus}&type=${arg.proposalType}${arg.dataInicial ? `&dataInicial=${arg.dataInicial}&dataFinal=${arg.dataFinal}` : ''}&userId=${getStorageUserId()}${arg.selectedPlan ? `&plan=${arg.selectedPlan}` : ''}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const fetchAll = async (arg) => {
  return axios.get(`${API_URL}/proposals/all?status=${arg.proposalStatus}&type=${arg.proposalType}${arg.dataInicial ? `&dataInicial=${arg.dataInicial}&dataFinal=${arg.dataFinal}` : ''}${arg.selectedPlan ? `&plan=${arg.selectedPlan}` : ''}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const get = async (id) => {
  return axios.get(`${API_URL}/proposals/proposal/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const search = async (query) => {
  return axios.get(`${API_URL}/proposals/search?q=${query}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const update = async (data) => {
    return axios.put(`${API_URL}/proposals`, data,
        {
            headers: { Authorization: `Bearer ${getStorageUserToken()}` }
        });
};

export const exportProposal = async (data) => {
    return axios.post(`https://www4.uniodontomaceio.com.br/ws_chatbot/gerarProposta/`, data,
        {
            headers: { Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC93d3c0LnVuaW9kb250b21hY2Vpby5jb20uYnIiLCJsb2dpbiI6IkpVQ0VMSU8iLCJpZCI6IjEyNSIsImRhdGFob3JhIjoxNTg0MzU3MjA1fQ.KFJQD9aY2jCXo2rNciz2dy-_sHq87rkFQ5G98J8ZZjU`,
            'Content-Type': 'application/json'}
        });
};

export const remove = async (id) => {
  return axios.delete(`${API_URL}/proposals/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};