import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import * as constants from '../../constantsuniodonto';

import { useLocation, useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const userService = require('../../services/UserService');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UsersPage() {
  
  const {search} = useLocation();
  
  const [isEdit, setIsEdit] = React.useState(false);
  const [roles, setRoles] = React.useState(constants.userRoleList);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [requestMessage, setRequestMessage] = React.useState('');
  const [entityID, setEntityID] = React.useState(null);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = React.useState(false);
  const [user, setUser] = React.useState({
    name: '',
    email: '',
    role: '',
    password: '',
    password_confirmantion: ''
  });
  
  const classes = useStyles();
  
  React.useEffect(() => {
    let id;
    if(search.indexOf('id=') !== -1){
      id = search.replace('?id=', '');
      setEntityID(id);
      userService.get(id).then((response) => {
        setUser(response.data);
        setIsEdit(true);
      });
    }
  },[]);
  
  let history = useHistory();
  
  function handleSaveButton(){
    if(isEdit){
      userService.update(user).then((response) => {
        setRequestMessage('Usuário Atualizado com Sucesso');
        setOpenSuccess(true);
      });
    }else{
      userService.register(user).then((response) => {
        setRequestMessage('Usuário Criado com Sucesso');
        setUser(response.data.user);
        history.push(`${constants.SITE_URL}/admin/create-user?id=${response.data.user._id}`);
        setEntityID(response.data.user._id);
        setIsEdit(true);
        setOpenSuccess(true);
      });
    }
  }
  
  function handleDeleteButton(){
    userService.remove(entityID).then((response) => {
      history.push(`${constants.SITE_URL}/admin/users`);
      setOpenSuccess(true);
      setRequestMessage('Usuário Removido com Sucesso');
      handleCloseRemoveConfirmation();
    }, () => {
      setRequestMessage(`Problema ao remover o Usuário`);
      setOpenError(true);
      handleCloseRemoveConfirmation();
    });
  }
  
  const closeAlert = (event, reason) => {
    setOpenSuccess(false);
    setOpenError(false);
  };
  
  const handleClickOpenRemoveConfirmation = () => {
    setOpenRemoveConfirmation(true);
  };
  
  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Criar Usuário</h4>
              <p className={classes.cardCategoryWhite}>Insira os dados do usuário e sua função</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: user.name,
                      onChange: (event) => {
                        setUser({
                          ...user,
                          name: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: user.email,
                      onChange: (event) => {
                        setUser({
                          ...user,
                          email: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Função"
                    id="role"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: user.role,
                      options: roles,
                      onChange: (event) => {
                        setUser({
                          ...user,
                          role: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              {!isEdit && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Senha"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: user.password,
                        type: 'password',
                        onChange: (event) => {
                          setUser({
                            ...user,
                            password: event.target.value
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Comfirmação de Senha"
                      id="password_confirmation"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: user.password_confirmation,
                        type: 'password',
                        onChange: (event) => {
                          setUser({
                            ...user,
                            password_confirmation: event.target.value
                          });
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleSaveButton}>Salvar Usuário</Button>
              {isEdit && (
                <Button color="danger" onClick={handleClickOpenRemoveConfirmation}>Excluir Usuário</Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="success">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="error">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openRemoveConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRemoveConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Alerta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer remover esse registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteButton} color="danger">
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
