import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useLocation, useHistory } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import * as constants from '../../constantsuniodonto';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const customerService = require('../../services/CustomerService');
const finantialResponsibleService = require('../../services/FinantialResponsibleService');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UsersPage() {
  
  const {search} = useLocation();
  
  const [isEdit, setIsEdit] = React.useState(false);
  const [typeList, setTypeList] = React.useState(constants.userTypeList);
  const [sexList, setSexList] = React.useState(constants.sexList);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [requestMessage, setRequestMessage] = React.useState('');
  const [plansList, setPlansList] = React.useState([]);
  const [entityID, setEntityID] = React.useState(null);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = React.useState(false);
  const [finantialResponsibleList, setFinantialResponsibleList] = React.useState([]);
  
  const [customer, setCustomer] = React.useState({
    name: '',
    born: '',
    phone: '',
    social_number: '',
    email: '',
    sex: true,
    postalCode: '',
    parentName: '',
    neightborhood: '',
    city: '',
    address: '',
    complement: '',
    number: '',
    password: '',
    password_validation: ''
  });
  
  React.useEffect(() => {
    let id;
    if(search.indexOf('id=') !== -1){
      id = search.replace('?id=', '');
      setEntityID(id);
      customerService.get(id).then((response) => {
        setCustomer(response.data);
        setIsEdit(true);
      });
    }
  },[]);

  React.useEffect(() => {
    finantialResponsibleService.fetch().then((response) => {
      let list = [];
      for(let i = 0, ilen = response.data.length; i < ilen; i++){
        list.push({
          title: response.data[i].name,
          value: JSON.stringify(response.data[i])
        });
      }
      setFinantialResponsibleList(list);
    });
  },[]);
  
  let history = useHistory();
  
  function handleSaveButton(){
    if(isEdit){
      customerService.update(customer).then((response) => {
        setRequestMessage('Cliente Atualizado com Sucesso');
        setOpenSuccess(true);
      });
    }else{
      customerService.create(customer).then((response) => {
        setRequestMessage('Cliente Criado com Sucesso');
        setCustomer(response.data.customer);
        history.push(`${constants.SITE_URL}/admin/create-customer?id=${response.data.customer._id}`);
        setEntityID(response.data.customer._id);
        setIsEdit(true);
        setOpenSuccess(true);
      });
    }
  }
  
  function handleDeleteButton(){
    customerService.remove(entityID).then((response) => {
      history.push(`${constants.SITE_URL}/admin/customer`);
      setOpenSuccess(true);
      setRequestMessage('Cliente Removido com Sucesso');
      handleCloseRemoveConfirmation();
    }, () => {
      setRequestMessage(`Problema ao remover Cliente`);
      setOpenError(true);
      handleCloseRemoveConfirmation();
    });
  }
  
  const closeAlert = (event, reason) => {
    setOpenSuccess(false);
    setOpenError(false);
  };
  
  const handleClickOpenRemoveConfirmation = () => {
    setOpenRemoveConfirmation(true);
  };
  
  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };
  
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Adicionar Cliente</h4>
              <p className={classes.cardCategoryWhite}>Insira todos os dados do novo cliente</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.name,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          name: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Nascimento"
                    id="born"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.born,
                      type: 'text',
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          born: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Telefone"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.phone,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          phone: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="CPF"
                    id="social_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.social_number,
                      type: 'number',
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          social_number: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.email,
                      type: 'email',
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          email: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Sexo"
                    id="sex"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.sex,
                      options: sexList,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          sex: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="CEP"
                    id="postalCode"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.postalCode,
                      type: 'number',
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          postalCode: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="CEP"
                    id="parentName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.parentName,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          parentName: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Logradouro"
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.address,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          address: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Número"
                    id="addressNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.number,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          number: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Complemento"
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.complement,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          complement: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Bairro"
                    id="neightborhood"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.neightborhood,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          neightborhood: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Cidade"
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer.city,
                      onChange: (event) => {
                        setCustomer({
                          ...customer,
                          city: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              {!isEdit && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Senha"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: customer.password,
                        type: 'password',
                        onChange: (event) => {
                          setCustomer({
                            ...customer,
                            password: event.target.value
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Comfirmação de Senha"
                      id="password_confirmation"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: customer.password_validation,
                        type: 'password',
                        onChange: (event) => {
                          setCustomer({
                            ...customer,
                            password_validation: event.target.value
                          });
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleSaveButton}>Salvar Cliente</Button>
              {isEdit && (
                <Button color="danger" onClick={handleClickOpenRemoveConfirmation}>Excluir Cliente</Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="success">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="error">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openRemoveConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRemoveConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Alerta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer remover esse registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteButton} color="danger">
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
