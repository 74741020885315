import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import * as constants from '../../constantsuniodonto';

const customerService = require('../../services/CustomerService');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function CustomersIndex() {
  const [customers, setCustomers] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  
  React.useEffect(() => {
    customerService.fetch().then((response) => {
      let data = [];
      setCustomers(response.data);
      for(let i = 0, ilen = response.data.length; i < ilen; i++){
        data.push([
          response.data[i].name,
          response.data[i].age,
          response.data[i].address,
          response.data[i].type,
          response.data[i].social_number,
          response.data[i].plan,
          response.data[i].dependents,
          response.data[i].email
        ])
      }
      setTableData(data);
    })
  }, []);
  
  let history = useHistory();
  
  const rowClickAction = (event, prop, key) => {
    history.push(`${constants.SITE_URL}/admin/create-customer?id=${customers[key]._id}`);
  };
  
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Usuários</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de usuários cadastrados no sistema
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Nome", "Idade", "Endereço", "Tipo", "CPF", "Dependentes", "Email"]}
              tableData={tableData}
              rowClickAction={rowClickAction}
              hover
            />
          </CardBody>
          <CardFooter>
            <Link to={`${constants.SITE_URL}/admin/create-customer`}>
              <Button color="primary" variant="outlined">Adicionar Cliente</Button>
            </Link>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}