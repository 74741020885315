module.exports = {
  // API_URL: 'http://localhost:3001',
  SITE_URL: '',
  API_URL: require('env').API_URL,
  userRoleList: [
    {title: 'Administrador', value: 'Administrador'},
    {title: 'Vendedor', value: 'Vendedor'},
    {title: 'Cadastro', value: 'Cadastro'},
    {title: 'Gerente', value: 'Gerente'}
  ],
  planTypeList: [
    {title: 'Pessoal', value: 'Pessoal'},
    {title: 'Empresarial', value: 'Empresarial'},
  ],
  planTargetList: [
    {title: 'Clínico', value: 'Clínico'},
    {title: 'Ortodôntico', value: 'Ortodôntico'},
  ],
  planPersonList: [
    {title: 'Servidor Público', value: 'Servidor Público'},
    {title: 'Individual/Familiar', value: 'Individual/Familiar'},
    {title: 'Estudante', value: 'Estudante'},
    {title: 'Beneficiário de plano médico/hospitalar', value: 'Beneficiário de plano médico/hospitalar'},
  ],
  companyList: [
    {title: 'ASFAL', value: 'ASFAL'},
    {title: 'Genérico', value: 'GENERICO'}
  ],
  paymentTypeList: [
    {title: 'Boleto do ASFAL Saúde', value: 'Boleto do ASFAL Saúde'},
    {title: 'Boleto', value: 'Boleto'},
    {title: 'Cartão', value: 'Cartão'},
    {title: 'Débito em conta', value: 'Débito em conta'},
    {title: 'Empresa', value: 'Empresa'}
  ],
  recurrencyList: [
    {title: 'Mensal', value: 'Mensal'},
    {title: 'Anual', value: 'Anual'}
  ],
  proposalStatusList: [
    {title: 'Rascunho', value: 'Rascunho'},
    {title: 'Pendente', value: 'Pendente'},
    {title: 'Aprovada', value: 'Aprovada'},
    {title: 'Concluída', value: 'Concluída'},
    {title: 'Aguardando', value: 'Aguardando'},
    {title: 'Excluída', value: 'Excluída'}
  ],
  sexList: [
    {title: 'Masculino', value: 'Masculino'},
    {title: 'Feminino', value: 'Feminino'}
  ],
  dependentTypeList: [
    {title: 'Cônjuge', value: 'Cônjuge'},
    {title: 'Filho(a)', value: 'Filho(a)'},
    {title: 'Filho', value: 'Filho'},
    {title: 'Agregado', value: 'Agregado'},
    {title: 'Agregado(a)', value: 'Agregado(a)'},
    {title: 'Pai', value: 'Pai'},
    {title: 'Mãe', value: 'Mãe'}
  ]
};