import axios from 'axios';
import * as constants from '../constantsuniodonto';

const API_URL = constants.API_URL;

export const register = (data) => {
  return axios.post(`${API_URL}/users`, data,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const fetch = () => {
  return axios.get(`${API_URL}/users`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const get = (id) => {
  return axios.get(`${API_URL}/users/user/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const search = (therm) => {
  return axios.get(`${API_URL}/users/search?q=${therm}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const update = (data) => {
  return axios.put(`${API_URL}/users`, data,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const remove = (id) => {
  return axios.delete(`${API_URL}/users/${id}`,
    {
      headers: { Authorization: `Bearer ${getStorageUserToken()}` }
    });
};

export const addStorageUser = (data) => {
  data.data.user['token'] = data.data.token;
  localStorage.setItem('uniodonto-admin-user', JSON.stringify(data.data.user));
};

export const removeStorageUser = () => {
  localStorage.removeItem('uniodonto-admin-user');
};

export const getStorageUser = () => {
  const localUser = localStorage.getItem('uniodonto-admin-user');
  return localUser ? JSON.parse(localUser) : null;
};

export const getStorageUserRole = () => {
  let localUser = localStorage.getItem('uniodonto-admin-user');
  if(localUser !== null) {
    localUser = JSON.parse(localUser);
    return localUser.role;
  }else{
    return null;
  }
};

export const getStorageUserToken = () => {
  let localUser = localStorage.getItem('uniodonto-admin-user');
  if(localUser !== null) {
    localUser = JSON.parse(localUser);
    return localUser.token;
  }else{
    return null;
  }
};

export const getStorageUserId = () => {
  let localUser = localStorage.getItem('uniodonto-admin-user');
  if(localUser !== null) {
    localUser = JSON.parse(localUser);
    return localUser._id;
  }else{
    return null;
  }
};
